import { useContext } from "react";
import { EthereumWalletProviderContext } from "../../ethereum-wallet-provider";

export const useEthereumWallet = () => {
  const {
    connect,
    account,
    token,
    network,
    balance,
    broadcastDeposit,
    isBroadcastingDeposit,
    depositTxHash,
    broadcastDepositError,
    loading,
    setDepositTxHash,
    provider,
    getFeeData,
    connectionError,
    resetConnectionError,
  } = useContext(EthereumWalletProviderContext);
  return {
    connect,
    account,
    token,
    network,
    balance,
    broadcastDeposit,
    isBroadcastingDeposit,
    depositTxHash,
    broadcastDepositError,
    loading,
    setDepositTxHash,
    provider,
    getFeeData,
    connectionError,
    resetConnectionError,
  };
};

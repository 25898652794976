"use client";

import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export const CURRENT_ETH_ADDRESS_STORAGE_KEY = "currentETHAddress";

export const useCurrentETHAddress = () => {
  const [isClient, setIsClient] = useState(false);

  // https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
  // We need to make sure that we're "on the client" before we use `currentETHAddress`, we get hydration errors otherwise.
  useEffect(() => {
    setIsClient(true);
  }, []);

  const [currentETHAddress, setCurrentETHAddress] = useLocalStorage<
    string | undefined
  >(CURRENT_ETH_ADDRESS_STORAGE_KEY, undefined);

  return {
    currentETHAddress: isClient ? currentETHAddress : undefined,
    setCurrentETHAddress,
  };
};

"use client";

import { useEffect, useState, useCallback } from "react";
import { serializeError } from "eth-rpc-errors";
import { Protocol, Network } from "@figmentjs/protocols";
import {
  getAccounts,
  getChainId,
  ProviderManager,
  ProviderType,
  requestPermissions,
} from "../../utils";
import { useCurrentETHAddress } from "../use-current-eth-address";
import {
  SetWalletProvider,
  WalletConnectProvider,
  Provider,
} from "./use-wallet-provider.types";
import { chainIdToNetworkMap, isIframe } from "./use-wallet-provider.utils";
import { getIFrameProvider, getWalletConnectProvider } from "./providers";
import { addListeners, removeListeners } from "./use-wallet-provider.utils";

export const useWalletProvider = () => {
  const [providerManager, setProviderManager] = useState<
    ProviderManager | undefined
  >();
  const [account, setAccount] = useState<string>();
  const { setCurrentETHAddress } = useCurrentETHAddress();
  const [token, setToken] = useState<Protocol | undefined>();
  const [network, setNetwork] = useState<Network | undefined>();
  const [loading, setLoading] = useState(false);
  const [connectionError, setConnectionError] = useState<string | null>(null);
  const [walletConnectProvider, setWalletConnectProvider] =
    useState<WalletConnectProvider>();

  const handleAccountsChanged = useCallback(
    (accounts: unknown) => {
      setAccount((accounts as string[])[0]);
      setCurrentETHAddress((accounts as string[])[0]);
    },
    [setCurrentETHAddress]
  );
  const handleChainChanged = useCallback(
    (chainId: unknown) => {
      setAccount(undefined);
      setCurrentETHAddress(undefined);
      setProviderManager(undefined);
      setToken(Protocol.ETHEREUM);
      setNetwork(chainIdToNetworkMap[Number(chainId)]);
    },
    [setCurrentETHAddress]
  );
  const handleDisconnect = useCallback(async () => {
    setAccount(undefined);
    setCurrentETHAddress(undefined);
    setProviderManager(undefined);
    setToken(undefined);
    setNetwork(undefined);
    setLoading(false);
  }, [setCurrentETHAddress, walletConnectProvider]);

  const setWalletProvider: SetWalletProvider = useCallback(
    async ({ preferredProvider } = {}) => {
      setLoading(true);

      try {
        const iframeProvider = getIFrameProvider();
        const isIFrame = isIframe();

        // If window.ethereum exists, we can use it to connect to MetaMask.
        // Otherwise, the promise used to request accounts should resolve
        // to an empty array.
        const metaMaskAccountsRequesting = window.ethereum
          ? getAccounts(window.ethereum)
          : Promise.resolve([]);

        const wcShouldGetAccounts = walletConnectProvider?.accounts?.length;
        const walletConnectAccountsRequesting =
          walletConnectProvider && wcShouldGetAccounts
            ? getAccounts(walletConnectProvider)
            : Promise.resolve([]);

        const iframeAccountsRequesting =
          isIFrame && iframeProvider
            ? await getAccounts(iframeProvider)
            : Promise.resolve([]);

        const [metaMaskAccounts, walletProviderAccounts, iframeAccounts] =
          await Promise.all([
            metaMaskAccountsRequesting,
            walletConnectAccountsRequesting,
            iframeAccountsRequesting,
          ]);

        let provider: Provider | undefined;
        let providerType: ProviderType | undefined;

        const setProviderToWalletConnect = async () => {
          setProviderManager(
            new ProviderManager(
              walletConnectProvider!,
              ProviderType.WALLETCONNECT
            )
          );
          handleAccountsChanged(walletProviderAccounts);
          provider = walletConnectProvider;
          providerType = ProviderType.WALLETCONNECT;
        };
        const setProviderToMetaMask = () => {
          setProviderManager(
            new ProviderManager(window.ethereum!, ProviderType.METAMASK)
          );
          handleAccountsChanged(metaMaskAccounts);
          provider = window.ethereum;
          providerType = ProviderType.METAMASK;
        };
        const setProviderToIFrame = () => {
          setProviderManager(
            new ProviderManager(iframeProvider!, ProviderType.IFRAME)
          );
          handleAccountsChanged(iframeAccounts);
          provider = iframeProvider;
          providerType = ProviderType.IFRAME;
        };

        const onlyWCConnected =
          !metaMaskAccounts?.length && walletProviderAccounts?.length;
        const onlyMMConnected =
          !walletProviderAccounts?.length && metaMaskAccounts?.length;
        const bothWCAndMMConnected =
          metaMaskAccounts?.length && walletProviderAccounts?.length;

        if (isIFrame) {
          setProviderToIFrame();
        } else if (
          onlyWCConnected ||
          (bothWCAndMMConnected &&
            preferredProvider === ProviderType.WALLETCONNECT)
        ) {
          await setProviderToWalletConnect();
        } else if (
          onlyMMConnected ||
          (bothWCAndMMConnected && preferredProvider === ProviderType.METAMASK)
        ) {
          setProviderToMetaMask();
        }

        // If there is no provider at this point, the user doesn't have
        // window.ethereum (e.g. MetaMask) or a WalletConnect wallet connected.
        // The user will need to manually connect.
        if (!provider) {
          setLoading(false);
          return;
        }

        const chainId = await getChainId(provider);
        setToken(Protocol.ETHEREUM);
        setNetwork(chainIdToNetworkMap[Number(chainId)]);

        addListeners({
          provider,
          providerType,
          handleAccountsChanged,
          handleChainChanged,
          handleDisconnect,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [
      handleAccountsChanged,
      handleChainChanged,
      handleDisconnect,
      walletConnectProvider,
    ]
  );

  const connect = useCallback(
    async ({ useWalletConnect = false } = {}) => {
      const abortConnection = () => {
        handleAccountsChanged([]);
        setProviderManager(undefined);
      };

      setConnectionError(null);
      setLoading(true);

      const ethereumProvider = useWalletConnect
        ? walletConnectProvider
        : window?.ethereum;

      if (useWalletConnect) {
        try {
          // This opens the WC modal.
          await walletConnectProvider?.connect();
        } catch (e) {
          // An error will be thrown if the connection is rejected.
          // This error can be ignored.

          abortConnection();
          setLoading(false);
          return;
        }
      }

      if (!ethereumProvider) {
        setLoading(false);
        return;
      }

      try {
        await requestPermissions(ethereumProvider);
        await setWalletProvider({
          preferredProvider: useWalletConnect
            ? ProviderType.WALLETCONNECT
            : ProviderType.METAMASK,
        });
      } catch (e) {
        // An error will be thrown if the connection is rejected.
        // This error can be ignored.
        const error = serializeError(e);
        const errorMessage =
          (error.data as any)?.originalError?.code === "ACTION_REJECTED"
            ? "Wallet permissions request rejected"
            : "A wallet permissions request is currently pending. Please accept the request to continue.";

        setConnectionError(errorMessage);
        abortConnection();
      } finally {
        setLoading(false);
      }
    },
    [handleAccountsChanged, setWalletProvider, walletConnectProvider]
  );

  useEffect(() => {
    const init = async () => {
      setWalletConnectProvider(await getWalletConnectProvider());
    };

    if (!walletConnectProvider && !isIframe()) {
      init();
    }
  }, [walletConnectProvider]);

  useEffect(() => {
    if (!providerManager && (walletConnectProvider || isIframe())) {
      setWalletProvider();
    }

    return () => {
      removeListeners({
        providerManager,
        handleAccountsChanged,
        handleChainChanged,
        handleDisconnect,
      });
    };
  }, [
    handleAccountsChanged,
    handleChainChanged,
    handleDisconnect,
    providerManager,
    setWalletProvider,
    walletConnectProvider,
  ]);

  return {
    provider: providerManager,
    account,
    token,
    network,
    loading,
    connect,
    connectionError,
    resetConnectionError: () => setConnectionError(null),
  };
};

import { Network } from "@figmentjs/protocols";
import { ProviderType, ProviderManager } from "../../utils";
import {
  Provider,
  WalletConnectProvider,
  IFrameProvider,
  ExternalProvider,
} from "./use-wallet-provider.types";

// eslint-disable-next-line turbo/no-undeclared-env-vars
export const DATAHUB_API_KEY = process.env.NEXT_PUBLIC_DATAHUB_API_KEY;
export const MAINNET_CHAIN_ID = 1;
export const GOERLI_CHAIN_ID = 5;
export const HOLESKY_CHAIN_ID = 17000;
export const GOERLI_RPC_URL = `https://ethereum-goerli--rpc.datahub.figment.io/apikey/${DATAHUB_API_KEY}`;
export const MAINNET_RPC_URL = `https://ethereum-mainnet--rpc.datahub.figment.io/apikey/${DATAHUB_API_KEY}`;
export const HOLESKY_RPC_URL = `https://ethereum-holesky--rpc.datahub.figment.io/apikey/${DATAHUB_API_KEY}`;

export const chainIdToNetworkMap: {
  [chainId: number]: Network;
} = {
  [MAINNET_CHAIN_ID]: Network.MAINNET,
  [GOERLI_CHAIN_ID]: Network.GOERLI,
  [HOLESKY_CHAIN_ID]: Network.HOLESKY,
};

export const isIframe = (): boolean => {
  try {
    const params = new URLSearchParams(window.self.location.search);
    const isLedger = window.ethereum?.isLedgerLive;
    const isEmbed = !!params.get("embed");

    if (isLedger) {
      return isEmbed;
    }

    return window.self !== window.top && isEmbed;
  } catch (error) {
    return false;
  }
};

type AddListenersProps = {
  provider: Provider;
  providerType?: ProviderType;
  handleDisconnect: () => void;
  handleChainChanged: (chainId: unknown) => void;
  handleAccountsChanged: (accounts: unknown) => void;
};

export const addListeners = ({
  provider,
  providerType,
  handleDisconnect,
  handleChainChanged,
  handleAccountsChanged,
}: AddListenersProps) => {
  if (providerType === ProviderType.WALLETCONNECT) {
    // Only needed for WalletConnect.
    (provider as WalletConnectProvider).on("session_delete", handleDisconnect);
  }

  if (providerType === ProviderType.IFRAME) {
    // Only needed for IFrame.
    (provider as IFrameProvider).on("close", handleDisconnect);
  }

  // @ts-expect-error https://github.com/figment-networks/figmentjs/pull/2212
  provider.on("chainChanged", handleChainChanged);
  // Note: MetaMask disconnects through the "accountsChanged" event.
  // @ts-expect-error https://github.com/figment-networks/figmentjs/pull/2212
  provider.on("accountsChanged", handleAccountsChanged);
};

type RemoveListenersProps = {
  providerManager?: ProviderManager;
  handleDisconnect: () => void;
  handleChainChanged: (chainId: unknown) => void;
  handleAccountsChanged: (accounts: unknown) => void;
};

export const removeListeners = ({
  providerManager,
  handleDisconnect,
  handleChainChanged,
  handleAccountsChanged,
}: RemoveListenersProps) => {
  if (providerManager?.type === ProviderType.IFRAME) {
    // Only needed for IFrame.
    (providerManager?.provider as IFrameProvider)?.removeAllListeners();
    return;
  }

  if (providerManager?.type === ProviderType.WALLETCONNECT) {
    // Only needed for WalletConnect.
    (providerManager?.provider as WalletConnectProvider)?.removeListener(
      "session_delete",
      handleDisconnect
    );
  }

  // @ts-expect-error https://github.com/figment-networks/figmentjs/pull/2212
  (providerManager?.provider as ExternalProvider)?.removeListener(
    "chainChanged",
    handleChainChanged
  );
  // @ts-expect-error https://github.com/figment-networks/figmentjs/pull/2212
  (providerManager?.provider as ExternalProvider)?.removeListener(
    "accountsChanged",
    handleAccountsChanged
  );
};

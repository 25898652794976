import {
  useEthereumWallet,
  EthereumWalletProvider,
} from "./EthereumWalletProvider";
import {
  isEthereumAddress,
  getEthereumAddress,
} from "./EthereumWalletProvider/utils";

const Providers = {
  useEthereumWallet,
  EthereumWalletProvider,
  isEthereumAddress,
  getEthereumAddress,
};

export default Providers;

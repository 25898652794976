import EthereumProvider from "@walletconnect/ethereum-provider";
import {
  GOERLI_RPC_URL,
  MAINNET_RPC_URL,
  HOLESKY_RPC_URL,
  MAINNET_CHAIN_ID,
  HOLESKY_CHAIN_ID,
} from "../../use-wallet-provider.utils";

export const getWalletConnectProvider = async () => {
  const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

  if (!projectId) {
    throw new Error("NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID is not set");
  }

  const existingWeb3Modal = document.querySelector("wcm-modal");
  if (existingWeb3Modal) {
    existingWeb3Modal.remove();
  }

  const provider = await EthereumProvider.init({
    projectId,
    optionalChains: [MAINNET_CHAIN_ID, HOLESKY_CHAIN_ID],
    // When using WalletConnect, we need to set the RPC URLs used by the provider.
    // These RPC URLs are only used with WalletConnect.
    // If using MetaMask, RPC URLs are handled by Metamask.
    rpcMap: {
      1: MAINNET_RPC_URL,
      5: GOERLI_RPC_URL,
      17000: HOLESKY_RPC_URL,
    },
    showQrModal: true,
    qrModalOptions: {
      themeVariables: {
        // Setting this to "1 higher" than our modal z-index
        "--w3m-z-index": "1000",
      },
    },
  });

  return provider;
};

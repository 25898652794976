import {
  ethers,
  Transaction,
  isAddress as isEthereumAddress,
  getAddress as getEthereumAddress,
  Eip1193Provider,
} from "ethers";
import EthereumProvider from "@walletconnect/ethereum-provider";
import { toWei } from "@figmentjs/utils";
import { Network } from "@figmentjs/protocols";
import { IFrameProvider, Provider } from "../hooks";
import { ProviderType } from "./provider-requests.types";
import {
  GOERLI_RPC_URL,
  MAINNET_RPC_URL,
  HOLESKY_RPC_URL,
} from "../hooks/use-wallet-provider/use-wallet-provider.utils";

type GetSendTransactionParams = {
  account: string;
  amount: string;
  to?: string;
  payload?: string;
};

type SendTransactionParams = {
  account: string;
  amount: string;
  network: Network;
  to?: string;
  payload?: string;
};

const networksToChainIdMap: {
  [key in Network]?: string;
} = {
  [Network.MAINNET]: "eip155:1",
  [Network.GOERLI]: "eip155:5",
  [Network.HOLESKY]: "eip155:17000",
};

export const networksToRPCMap: {
  [key in Network]?: string;
} = {
  [Network.MAINNET]: MAINNET_RPC_URL,
  [Network.GOERLI]: GOERLI_RPC_URL,
  [Network.HOLESKY]: HOLESKY_RPC_URL,
};

/**
 * ProviderManager stores instances of the Web3 provider
 * (e.g. window.ethereum, WalletConnect, etc.), as well
 * as a wrapped version of this provider using ethers.js (so that
 * we can get strong typing and utility functions (e.g. getBalance()).
 * The Web3 provider is used to handle certain functionality an ethers.js
 * provider cannot - namely, attaching event handlers.
 */
export class ProviderManager {
  public type?: ProviderType;
  public provider: Provider;
  public ethersProvider: ethers.BrowserProvider;

  constructor(provider: Provider, type: ProviderType) {
    this.type = type;
    this.provider = provider;
    this.ethersProvider = new ethers.BrowserProvider(
      provider as Eip1193Provider
    );
  }

  request = (provider: ethers.BrowserProvider) => {
    return provider.send;
  };

  getBalance = async (account: string) => {
    // The IFrameProvider is not able to be used through ethers, because it is older
    // and does not implement the required ".request()" (among other things).
    // So even though we are wrapping it in ethers in the constructor, we can't use it that way.
    // This applies to the other checks we do in this file as well.
    if ((this.provider as IFrameProvider).isIFrame) {
      const balance = await (this.provider as IFrameProvider).send(
        "eth_getBalance",
        [account, "latest"]
      );
      return balance;
    }

    const balance = await this.ethersProvider.getBalance(account);
    return balance.toString();
  };

  sendTransaction = ({
    account,
    amount,
    to,
    payload,
    network,
  }: SendTransactionParams) => {
    const params = [
      this.getSendTransactionParams({
        account,
        to,
        amount,
        payload,
      }),
    ];

    if (this.type === ProviderType.WALLETCONNECT) {
      const walletConnectProvider = this.provider as EthereumProvider;

      const topic = walletConnectProvider?.session?.topic;
      const chainId = networksToChainIdMap[network];

      if (!topic) {
        throw new Error("There is no WalletConnect topic");
      }
      if (!chainId) {
        throw new Error("There is no WalletConnect chainId");
      }

      return walletConnectProvider.signer.client.request({
        topic,
        chainId,
        request: {
          method: "eth_sendTransaction",
          params,
        },
        expiry: 3 * 60 * 60, // 3 hours in seconds
      });
    } else if (this.type === ProviderType.IFRAME) {
      return (this.provider as IFrameProvider).send(
        "eth_sendTransaction",
        params
      );
    }

    return this.ethersProvider.send("eth_sendTransaction", params);
  };

  getSendTransactionParams = ({
    account,
    to,
    amount,
    payload,
  }: GetSendTransactionParams) => {
    const value = `0x${toWei(amount)}`;

    if (payload) {
      const tx = Transaction.from(payload);

      const hexGasLimit = `0x${tx.gasLimit.toString(16)}`;

      return {
        from: account,
        to: tx.to,
        value,
        data: tx.data,
        gas: hexGasLimit,
      };
    }

    return {
      from: account,
      to,
      value,
    };
  };

  getTransactionReceipt = (depositTxHash: string, network: Network) => {
    const rpcProvider = new ethers.JsonRpcProvider(networksToRPCMap[network]);

    return rpcProvider.getTransactionReceipt(depositTxHash);
  };

  getBlock = (blockNumber: number, network: Network) => {
    const rpcProvider = new ethers.JsonRpcProvider(networksToRPCMap[network]);

    return rpcProvider.getBlock(blockNumber);
  };

  getFeeData = (network: Network) => {
    const rpcProvider = new ethers.JsonRpcProvider(networksToRPCMap[network]);

    return rpcProvider.getFeeData();
  };
}

const getAccounts = async (provider: Provider): Promise<string[]> => {
  if ((provider as IFrameProvider).isIFrame) {
    const accounts = await (provider as IFrameProvider).send("eth_accounts");
    return accounts;
  }

  const ethersProvider = new ethers.BrowserProvider(
    provider as Eip1193Provider
  );
  const accounts = await ethersProvider.listAccounts();
  return accounts.map((account) => account.address);
};

const getChainId = async (provider: Provider) => {
  if ((provider as IFrameProvider).isIFrame) {
    const chainId = await (provider as IFrameProvider).send("net_version");
    return chainId;
  }

  const ethersProvider = new ethers.BrowserProvider(
    provider as Eip1193Provider
  );
  const { chainId } = await ethersProvider.getNetwork();
  return chainId;
};

const requestPermissions = async (provider: Provider) => {
  const ethersProvider = new ethers.BrowserProvider(
    provider as Eip1193Provider
  );
  return ethersProvider.send("eth_requestAccounts", []);
};

export {
  isEthereumAddress,
  getEthereumAddress,
  getAccounts,
  getChainId,
  requestPermissions,
};

"use client";

import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  memo,
} from "react";
import {
  EthereumWalletProviderState,
  EthereumWalletProviderProps,
  BroadcastDeposit,
  GetFeeData,
} from "./ethereum-wallet-provider.types";
import { useWalletProvider } from "./hooks";

export const EthereumWalletProviderContext =
  createContext<EthereumWalletProviderState>({
    connect: async () => {
      throw new Error("connect not implemented");
    },
    account: undefined,
    token: undefined,
    network: undefined,
    balance: undefined,
    broadcastDeposit: async () => {
      throw new Error("broadcastDeposit not implemented");
    },
    isBroadcastingDeposit: false,
    broadcastDepositError: undefined,
    depositTxHash: undefined,
    loading: false,
    setDepositTxHash: () => {
      throw new Error("setDepositTxHash not implemented");
    },
    provider: undefined,
    getFeeData: async () => {
      throw new Error("getFeeData not implemented");
    },
    connectionError: null,
    resetConnectionError: () => {
      throw new Error("resetConnectionError not implemented");
    },
  });

const EthereumWalletProvider: React.FC<EthereumWalletProviderProps> = ({
  children,
}) => {
  const {
    account,
    provider,
    token,
    network,
    loading: loadingWalletProvider,
    connect,
    connectionError,
    resetConnectionError,
  } = useWalletProvider();
  const [balance, setBalance] = useState<string>();
  const [isBroadcastingDeposit, setIsBroadcastingDeposit] =
    useState<boolean>(false);
  const [depositTxHash, setDepositTxHash] = useState<string>();
  const [broadcastDepositError, setBroadcastDepositError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const initWallet = useCallback(async () => {
    if (!provider || !token || !account) return;

    setLoading(true);

    try {
      const newBalance = await provider.getBalance(account);
      setBalance(newBalance || undefined);
    } finally {
      setLoading(false);
    }
  }, [account, token, provider]);

  const broadcastDeposit: BroadcastDeposit = async ({
    amount,
    ...remainingArgs
  }) => {
    setIsBroadcastingDeposit(true);
    setDepositTxHash(undefined);
    setBroadcastDepositError(undefined);

    if (!account) {
      throw new Error("There is no account connected");
    }
    if (!network) {
      throw new Error("There is no valid chain connected");
    }

    try {
      const depositTxHash = await provider!.sendTransaction({
        account: account,
        amount,
        network,
        ...remainingArgs,
      });

      if (!depositTxHash || typeof depositTxHash !== "string") {
        throw new Error("Transaction hash not available");
      }

      setDepositTxHash(depositTxHash);

      const interval = setInterval(async () => {
        const receipt = await provider!.getTransactionReceipt(
          depositTxHash,
          network
        );

        if (receipt?.status && receipt?.blockNumber) {
          const block = await provider!.getBlock(receipt.blockNumber, network);

          if (block && block?.transactions?.includes(depositTxHash)) {
            clearInterval(interval);

            const txSuccess = !!Number(receipt.status);
            if (!txSuccess) {
              setBroadcastDepositError("TX_NOT_SUCCESSFUL");
            }

            const newBalance = await provider!.getBalance(account!);
            setBalance(newBalance || undefined);

            setIsBroadcastingDeposit(false);
          }
        }
      }, 1000);
    } catch (error: any) {
      setIsBroadcastingDeposit(false);
      setDepositTxHash(undefined);

      if (
        // MetaMask
        error.code === "ACTION_REJECTED" ||
        [
          // WalletConnect - Standard
          "User rejected methods",
          // WalletConnect - Ledger Live
          "Transaction declined",
          // WalletConnect - MetaMask
          "User rejected the transaction",
        ].includes(error?.message) ||
        // dApp - Ledger Live
        error?.reason?.includes("Transaction declined")
      ) {
        setBroadcastDepositError("TX_CANCELED");
      } else if (
        // WalletConnect tx timeout
        JSON.stringify(error) === "{}"
      ) {
        setBroadcastDepositError("TX_TIMEOUT");
      } else {
        setBroadcastDepositError(JSON.stringify(error));
        throw error;
      }
    }
  };

  const getFeeData: GetFeeData = async () => {
    if (!provider || !network) return;
    const feeData = await provider.getFeeData(network);
    return feeData;
  };

  useEffect(() => {
    initWallet();
  }, [initWallet]);

  return (
    <EthereumWalletProviderContext.Provider
      value={{
        connect,
        account,
        token,
        network,
        balance,
        broadcastDeposit,
        isBroadcastingDeposit,
        depositTxHash,
        broadcastDepositError,
        loading: loading || loadingWalletProvider,
        setDepositTxHash,
        getFeeData,
        provider,
        connectionError,
        resetConnectionError,
      }}
    >
      {children}
    </EthereumWalletProviderContext.Provider>
  );
};

export default memo(EthereumWalletProvider);
